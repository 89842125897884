import { info } from 'sitedata';
import useAuthContext from 'hooks/useAuthContext';

const useLinks = () => {
  const { authData } = useAuthContext();
  const isAdmin = authData?.admin;
  const isSuperAdmin = authData?.superAdmin;
  const { manageBrokerSiteAccess } = info;

  return {
    linksBlock1: [
      { path: '/', text: 'Dashboard' },
      { path: '/calendar/schedule', text: 'Calendar' },
      { path: '/people', text: 'People' },
      { path: '/vendors', text: 'Vendors' },
      { path: '/notifications', text: 'Notifications' },
      
      //{ path: '/requests', text: 'Contact Requests', hidden: !manageBrokerSiteAccess },
      //{ path: '/transactions', text: 'Transactions', hidden: !isAdmin }
    ],
    linksBlock2: [
      { path: '/documents', text: 'Documents' },
      { path: '/signatures', text: 'Signatures' },
      { path: '/signed-documents', text: 'Signed Documents' },
      { path: '/form-creation', text: 'Form Creation', newTab: true, hidden: !isSuperAdmin }
    ],
    linksBlock3: [
      { path: '/profile', text: 'Profile', hidden: !isSuperAdmin },
      { path: '/user-management', text: 'Team Management', hidden: !isAdmin || !isSuperAdmin },
      {
        path: '/featured',
        text: 'Site Administration',
        hidden: !isAdmin || !manageBrokerSiteAccess || !isSuperAdmin
      },
      { path: 'TOOLS', text: 'Tools' }
    ]
  };
};

export default useLinks;
